/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.fic-link-emoji {
    font-size: 1.5em;
    text-decoration: none;
}

.fic-link-emoji:hover {
    text-decoration: none;
}

.tool-nav-container {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
}
